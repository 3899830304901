import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { error } from 'console';


@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.scss'],
  animations: [
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class PageContactComponent implements OnInit {
  title = 'CONTACTS';
  showContent = false;
  isBurgerMenuOpen = false;
  isModalOpen = false;

  contactName = '';
  contactPoste = '';
  contactEmail = '';
  isLoading = false;
  contactData: any[] = [];
  isAuthenticated: boolean = false;
  contactOBJ = {
    name: '',
    email: '',
    message: ''
  };

  constructor(private router: Router,private http: HttpClient , private viewportScroller: ViewportScroller) {}


  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    this.typeTitleText();
    this.loadContactData();
  }

  deleteContact(id: number) {
    if (confirm('Are you sure you want to delete this Contact?')) {
      this.isLoading = true;

      this.http.delete(`${environment.apiUrl}/teams/delete/${id}`)
        .subscribe({
          next: (res) => {
            console.log('Contact deleted successfully:', res);
           this.contactData = this.contactData.filter(contact => contact.id !== id);
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Contact:', error);
            this.isLoading = false;
          }
        });
    }
  }

  typeTitleText() {
    const titleElement = document.getElementById('title');
    if (titleElement) {
      const titleText = this.title;
      let index = 0;
      titleElement.textContent = '';

      const typeEffect = () => {
        if (index < titleText.length) {
          titleElement.textContent += titleText.charAt(index);
          index++;
          setTimeout(typeEffect, 150);
        } else {
          setTimeout(() => {
            this.showContent = true;
          }, 500);
        }
      };

      typeEffect();
    }
  }
  loadContactData() {
    this.http.get<any[]>(`${environment.apiUrl}/teams/all`)
      .subscribe({
        next: (res) => {
          this.contactData = res.map(contact => ({
            id:contact.id,
            name: contact.name,
            email: contact.email,
            job: contact.job
          }));
        },
        error: (error) => {
          console.error('Error fetching contact data:', error);
        }
      });
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.clearModalInputs();
  }

  confirmAddContact() {
    if (this.contactName && this.contactPoste && this.contactEmail) {
      // Prepare contact data
      const contactData = {
        name: this.contactName,
        email: this.contactEmail,
        job: this.contactPoste  // Adjusted field name
      };

      this.http.post(`${environment.apiUrl}/teams/add`, contactData)
        .subscribe({
          next: (res) => {
            console.log('Response:', res);
            this.clearModalInputs();
            this.closeModal();
          },
          error: (error) => {
            console.error('Error:', error);
          }
        });
    } else {
      console.warn('Please fill in all required fields.');
    }
  }

  clearModalInputs() {
    this.contactName = '';
    this.contactPoste = '';
    this.contactEmail = '';
  }

  sendContactInformation(): void {
    if (this.contactOBJ.name && this.contactOBJ.email && this.contactOBJ.message) {
      this.isLoading = true;

      this.http.post(`${environment.apiUrl}/contact/send`, this.contactOBJ)
        .subscribe({
          next: (res) => {
            console.log('Response:', res);
            this.contactOBJ = { name: '', email: '', message: '' };
          },
          error: (error) => {
            console.error('Error:', error);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    } else {
      console.warn('Please fill in all required fields.');
    }
  }


  navigateToMain() {
    this.router.navigate(['/']);
  }

  navigateToArtists() {
    this.router.navigate(['/artists']);
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']);
  }

  navigateToServices() {
    this.router.navigate(['/services']);
  }

  navigateToContact() {
    this.router.navigate(['/contact']);
  }

  navigateToFaq() {
    this.router.navigate(['/faq']);
  }


}
