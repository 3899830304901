<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-evenement">
    <!-- Navbar -->
    <div class="navbar">
        <div class="left-section">
          <div class="sticky-burgerbar">
            <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
          </div>
          <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
        </div>
        <div class="frame-3">
          <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
        </div>
      </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
      <div class="burger-menu">
        <button class="close-button" (click)="toggleBurgerMenu()">X</button>
        <ul>
            <li (click)="navigateToMain()">ACCUEIL</li>
          <li (click)="navigateToArtists()">ARTISTES</li>
          <li (click)="navigateToEvenement()">BOUTIQUE</li>
          <li (click)="navigateToServices()">SERVICES</li>
          <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
        </ul>
      </div>
    </div>

    <!-- Cover Section -->
    <div class="cover" [@slideInRight]>
      <div class="title">
        <h1>Boutique</h1>
        <button class="btn-add-event" *ngIf="isAuthenticated" (click)="openModal()">Add Event</button>
      </div>
    </div>

    <!-- Filter Input -->
    <div class="filter-section">
      <input type="text" placeholder="Rechercher artiste par nom" [(ngModel)]="filterText" (input)="filterEvents()" />
    </div>

    <!-- Events Section -->
    <div class="events-section">
        <h1>Prochain Évenement</h1>

      <div class="event-header">
        <div class="event-column">Artiste</div>
        <div class="event-column">Date</div>
        <div class="event-column">Adresse</div>
        <div class="event-column">Tickets</div>
      </div>
      <div class="event-row" *ngFor="let event of filteredEvents">
        <div class="event-column">
          <div class="event-content">
            <img src={{event.image}} alt="Artist">
            <div>{{ event.artistName }}</div>
          </div>
        </div>
        <div class="event-column">{{ event.date }}</div>
        <div class="event-column">{{ event.address }}</div>
        <div class="event-column">
          <button class="btn-buy-tickets" (click)="openTicketModal(event)">Tickets</button>
          <button class="btn-buy-tickets" *ngIf="isAuthenticated" (click)="deleteEvent(event.id)" >Delete Button</button>

        </div>
      </div>
    </div>
    <div class="Merch">
        <h1>Merch</h1>
    <h2>...</h2>
    </div>

    <!-- Modal for Adding Event -->
    <div *ngIf="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>Event Details</h2>
        <button class="modal-close-button" (click)="closeModal()">X</button>
        <div class="modal-body">
          <form>
            <label>Event Name*</label>
            <input type="text" [(ngModel)]="eventName" [ngModelOptions]="{standalone: true}" placeholder="Enter Event Name" required>

            <label>Artiste*</label>
            <input type="text" [(ngModel)]="artistName" [ngModelOptions]="{standalone: true}" placeholder="Enter Artiste Name" required>

            <label>Date*</label>
            <input type="date" [(ngModel)]="eventDate" [ngModelOptions]="{standalone: true}" placeholder="Enter Date" required>

            <label>Adresse*</label>
            <input type="text" [(ngModel)]="eventAddress" [ngModelOptions]="{standalone: true}" placeholder="Enter Address" required>

            <label>Image*</label>
            <input type="file" (change)="onFileChange($event)"  name="eventImage" required>
            <button type="button" (click)="confirmAddEvent()">Confirm</button>
            <button type="button" (click)="closeModal()">Cancel</button>
          </form>

        </div>
      </div>
    </div>

    <!-- Modal for Buying Tickets -->
    <div *ngIf="isTicketModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>Buy Tickets</h2>
        <button class="modal-close-button" (click)="closeTicketModal()">X</button>
        <div class="modal-body">
          <form>
            <label>Number of Tickets*</label>
            <input type="number" [(ngModel)]="ticketQuantity" name="ticketQuantity" placeholder="Enter Number of Tickets" required>

            <label>Email*</label>
            <input type="email" [(ngModel)]="ticketEmail" name="ticketEmail" placeholder="Enter your Email" required>

            <button type="button"  (click)="confirmPurchase()">
              <ng-container *ngIf="isLoading; else sendText">
                Paying...
            </ng-container>
            <ng-template #sendText>
                Pay
            </ng-template>

            </button>
            <button type="button" (click)="closeTicketModal()">Cancel</button>
          </form>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo-foot">
            <h1 class="footer-description notranslate">
              LE CROISSANT FERTILE
            </h1>
            <div class="footer-logo">
              <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
            </div>
          </div>
        <div class="footer-links">
          <div class="frame-186">
            <div class="text-website-links">Lien internet</div>
            <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
            <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>

          </div>
          <div class="frame-185">
            <div class="text-social-platforms">Platformes Sociales</div>
            <div class="text-instagram">
                <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>

              <div class="text-facebook">
                <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
              </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
