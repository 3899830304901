<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-services">
  <!-- Navbar -->
  <div class="navbar">
    <div class="left-section">
      <div class="sticky-burgerbar">
        <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
      </div>
      <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
    </div>
    <div class="frame-3">
      <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
    </div>
  </div>

  <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
    <div class="burger-menu">
      <button class="close-button" (click)="toggleBurgerMenu()">X</button>
      <ul>
        <li (click)="navigateToMain()">ACCUEIL</li>

        <li (click)="navigateToArtists()">ARTISTES</li>
        <li (click)="navigateToEvenement()">BOUTIQUE</li>
        <li (click)="navigateToServices()">SERVICES</li>
        <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
      </ul>
    </div>
  </div>

  <!-- Services Content -->
  <div class="services-header" [@slideInRight]>
    <h1>SERVICES</h1>
  </div>

  <button class="btn-add-service" *ngIf="isAuthenticated" (click)="openServiceModal()">Add Service</button>

  <div class="services-content">
    <div *ngFor="let service of services" class="service-item" [@fadeInContent]>
      <div class="service-text">
        <h2>{{ service.title }}</h2>
        <p>{{ service.description }}</p>
        <button class="button" *ngIf="isAuthenticated" (click)="deleteService(service.id)" >Delete Service</button>
      </div>
      <!-- <img [src]="service.image" [alt]="service.title"> -->
    </div>
  </div>

  <button class="button" (click)="navigateToContact()">CONTACTEZ NOUS</button>


  <!-- Modal for Adding Service -->
  <div *ngIf="isServiceModalOpen" class="modal-overlay">
    <div class="modal-content">
      <h2>ADD SERVICE</h2>
      <button class="modal-close-button" (click)="closeServiceModal()">X</button>
      <div class="modal-body">
        <input type="text" [(ngModel)]="newService.title" placeholder="Enter Service Title">
        <input type="text" [(ngModel)]="newService.description" placeholder="Enter Service Description">
        <input type="file" (change)="onFileSelected($event)">
        <button (click)="confirmService()">Confirm</button>
        <button (click)="closeServiceModal()">Cancel</button>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="footer">
    <div class="footer-content">
      <div class="logo-foot">
        <h1 class="footer-description notranslate">
          LE CROISSANT FERTILE
        </h1>
        <div class="footer-logo">
          <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
        </div>
      </div>
      <div class="footer-links">
        <div class="frame-186">
          <div class="text-website-links">Lien internet</div>
          <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
          <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>

        </div>
        <div class="frame-185">
          <div class="text-social-platforms">Platformes Sociales</div>
          <div class="text-instagram">
            <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>

          <div class="text-facebook">
            <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
      </div>
    </div>
  </div>
</div>
