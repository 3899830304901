<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-artists">
    <!-- Navbar -->
    <div class="navbar">
        <div class="left-section">
          <div class="sticky-burgerbar">
            <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
          </div>
          <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
        </div>
        <div class="frame-3">
          <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
        </div>
      </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
      <div class="burger-menu">
        <button class="close-button" (click)="toggleBurgerMenu()">X</button>
        <ul>
            <li (click)="navigateToMain()">ACCUEIL</li>

          <li (click)="navigateToArtists()">ARTISTES</li>
          <li (click)="navigateToEvenement()">BOUTIQUE</li>
          <li (click)="navigateToServices()">SERVICES</li>
          <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
        </ul>
      </div>
    </div>

    <!-- Cover Section -->
    <div class="cover" [@slideInRight]>
      <div class="title" id="title">
        <h1>Artists</h1>
      </div>
    </div>

    <!-- Add Artist Button -->
    <button class="btn-add-artist" *ngIf="isAuthenticated" (click)="openModal()">Add Artist</button>

    <!-- Search Input -->
    <div class="search-section">
      <input type="text" placeholder="Search artist by name" [(ngModel)]="filterText" (input)="filterArtists()" />
    </div>

    <!-- Artists Section -->
    <div class="artists-section">
      <div class="carousel-container">
        <div class="artist-carousel">
          <div *ngFor="let artist of filteredArtists" class="artist-card" >


            <div class="text-artist-name">{{ artist.name }}</div>
            <img [src]="artist.image" (click)="navigateToArtist(artist.id)" [alt]="artist.name">

            <button class="text-artist-delete" *ngIf="isAuthenticated" (click)="deleteArtist(artist.id)" >Delete Artist</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Artist -->
    <div *ngIf="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>ARTISTE DETAILS</h2>
        <button class="modal-close-button" (click)="closeModal()">X</button>
        <div class="modal-body">
          <form>
            <label>IMAGE*</label>
            <input type="file" (change)="onFileChange($event)" required name="artistImage">

            <label>ARTISTE*</label>
            <input type="text" [(ngModel)]="artistName" placeholder="Enter Artiste Name" required name="artistName">

            <label>BIOGRAPHY *</label>
            <textarea [(ngModel)]="biography" placeholder="Enter Biography" required name="biography"></textarea>

            <label>DISQUE</label>
            <input type="text" [(ngModel)]="disque" placeholder="Enter Disque Name" name="disque">

            <label>SPECTACLE</label>
            <input type="text" [(ngModel)]="spectacle" placeholder="Enter Spectacle Name" name="spectacle">

            <label>PRESSE</label>
            <input type="text" [(ngModel)]="presse" placeholder="Enter Presse Name" name="presse">

            <label>EDITION</label>
            <input type="text" [(ngModel)]="edition" placeholder="Enter Edition Name" name="edition">

            <label>SPOTIFY LINK</label>
            <input type="url" [(ngModel)]="spotifyLink" placeholder="Enter Spotify Link" name="spotifyLink">

            <label>APPLE MUSIC LINK</label>
            <input type="url" [(ngModel)]="appleMusicLink" placeholder="Enter Apple Music Link" name="appleMusicLink">

            <button type="button" (click)="confirmAddArtist()">Confirm</button>
            <button type="button" (click)="closeModal()">Cancel</button>
        </form>

        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo-foot">
            <h1 class="footer-description notranslate">
              LE CROISSANT FERTILE
            </h1>
            <div class="footer-logo">
              <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
            </div>
          </div>
        <div class="footer-links">
          <div class="frame-186">
            <div class="text-website-links">Lien internet</div>
            <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
            <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>

          </div>
          <div class="frame-185">
            <div class="text-social-platforms">Platformes Sociales</div>
            <div class="text-instagram">
                <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>

              <div class="text-facebook">
                <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
              </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
