import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


// Define the FAQ interface
interface Faq {
  id: number;
  question: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
}

@Component({

  selector: 'app-page-faq',
  templateUrl: './page-faq.component.html',
  styleUrls: ['./page-faq.component.scss'],
  animations: [
    trigger('fadeInLogoAndTitle', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('3s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class PageFaqComponent implements OnInit {
  isBurgerMenuOpen = false;
  isModalOpen = false;
  question: string = '';
  answer: string = '';
  faqs: Faq[] = [];
  isAuthenticated: boolean = false;
  isLoading=false;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    // Load existing FAQs from the API and append them to the faqs array
    this.getFaqs(`${environment.apiUrl}/faqs/all`).subscribe(
      (response) => {
        if (response.success) {
          this.faqs = [...this.faqs, ...response.data];
        } else {
          console.error('Failed to load FAQs');
        }
      },
      (error) => {
        console.error('Error fetching FAQs:', error);
      }
    );
  }

  getFaqs(url: string): Observable<{ success: boolean, data: Faq[] }> {
    return this.http.get<{ success: boolean, data: Faq[] }>(url);
  }

  toggleBurgerMenu(): void {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.clearModalInputs();
  }

  deleteFaq(id: number) {
    if (confirm(`Are you sure you want to delete this FAQ? :${id}`)) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/faqs/delete/${id}`)
        .subscribe({
          next: (res) => {
            console.log('FAQ deleted successfully:', res);
            this.faqs = this.faqs.filter(faq => faq.id !== id);

            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting FAQ:', error);
            this.isLoading = false;
          }
        });
    }
  }

  confirmAddFAQ(): void {
    if (this.question && this.answer) {
      const newFAQ = { question: this.question, answer: this.answer };

      this.http.post(`${environment.apiUrl}/faqs/add`, newFAQ).subscribe(
        response => {
          this.faqs.push({ ...newFAQ, id: Date.now(), createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() });
          this.closeModal();
        },
        error => {
          alert('Failed to add FAQ. Please try again.');
        }
      );
    } else {
      alert('Please fill out both fields.');
    }
  }

  clearModalInputs(): void {
    this.question = '';
    this.answer = '';
  }

  navigateToMain(): void {
    this.router.navigate(['/']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
      location.reload();
    });
  }

  navigateToArtists(): void {
    this.router.navigate(['/artists']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToEvenement(): void {
    this.router.navigate(['/evenement']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToServices(): void {
    this.router.navigate(['/services']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToContact(): void {
    this.router.navigate(['/contact']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToFaq(): void {
    this.router.navigate(['/faq']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

}
