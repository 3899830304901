import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss'],
  animations: [
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class ArtistsComponent implements OnInit {
  isBurgerMenuOpen = false;
  isModalOpen = false;
  image: string = '';
  artistName: string = '';
  biography: string = '';
  disque: string = '';
  spectacle: string = '';
  presse: string = '';
  edition: string = '';
  spotifyLink: string = '';
  appleMusicLink: string = '';
  filterText = '';
  selectedFile: File | null = null;
  isLoading=false;
  artists = [
    { id: 1, name: 'Artist 1', image: 'assets/images/muhoza.jpg' },
    { id: 2, name: 'Artist 2', image: 'assets/images/artist2.jpg' },
    { id: 3, name: 'Artist 3', image: 'assets/images/artist3.jpg' }
  ];

  filteredArtists = this.artists;
  isAuthenticated: boolean = false;

  constructor(private router: Router, private viewportScroller: ViewportScroller, private http: HttpClient) {}

  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }


  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    this.loadArtists();
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  navigateToMain() {
    this.router.navigate(['/']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToArtists() {
    this.router.navigate(['/artists']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToServices() {
    this.router.navigate(['/services']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToContact() {
    this.router.navigate(['/contact']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToFaq() {
    this.router.navigate(['/faq']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToArtist(artistId: number) {
    this.router.navigate(['/artist', artistId]).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }
  deleteArtist(id: number) {
    if (confirm(`Are you sure you want to delete this Artist? :${id}`)) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/artist/delete/${id}`)
        .subscribe({
          next: (res) => {
            console.log('Artist deleted successfully:', res);
            this.loadArtists();
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Artist:', error);
            this.isLoading = false;
          }
        });
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.clearModalInputs();
  }

  confirmAddArtist() {
    this.isLoading=true
    if (this.selectedFile && this.artistName && this.biography) {
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      formData.append('name', this.artistName);
      formData.append('biography', this.biography);
      formData.append('disk', this.disque);
      formData.append('spectacle', this.spectacle);
      formData.append('press', this.presse);
      formData.append('edition', this.edition);
      formData.append('spotifyLink', this.spotifyLink);
      formData.append('appleMusicLink', this.appleMusicLink);

      this.http.post(`${environment.apiUrl}/artist/add`, formData)
        .subscribe({
          next: (res) => {
            console.log('Artist added successfully:', res);
            this.isLoading=false
            this.closeModal();
          },
          error: (error) => {
            console.error('Error adding artist:', error);
            this.isLoading=false
          }
        });
    } else {
      console.error('Please fill in required fields and select an image.');
      this.isLoading=false
    }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  clearModalInputs() {
    this.image = '';
    this.artistName = '';
    this.biography = '';
    this.disque = '';
    this.spectacle = '';
    this.presse = '';
    this.edition = '';
    this.spotifyLink = '';
    this.appleMusicLink = '';
  }

  filterArtists() {
    this.filteredArtists = this.artists.filter(artist =>
      artist.name.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  loadArtists() {
    this.http.get<{ success: boolean; data: Array<{ id: number; image: string; name: string }> }>(`${environment.apiUrl}/artist/all`)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.artists = res.data.map(artist => ({
              id: artist.id,
              name: artist.name,
              image: `${environment.apiUrl}/${artist.image}`
            }));
            this.filteredArtists = this.artists; // Ensure the filtered list is updated
          } else {
            console.error('Error fetching artists: No success response');
          }
        },
        error: (error) => {
          console.error('Error fetching artists:', error);
        }
      });
  }

}
