<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-artist">
    <!-- Navbar -->
    <div class="navbar">
        <div class="left-section">
          <div class="sticky-burgerbar">
            <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
          </div>
          <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
        </div>
        <div class="frame-3">
          <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
        </div>
      </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
      <div class="burger-menu">
        <button class="close-button" (click)="toggleBurgerMenu()">X</button>
        <ul>
          <li (click)="navigateToMain()">ACCUEIL</li>
          <li (click)="navigateToArtists()">ARTISTES</li>
          <li (click)="navigateToEvenement()">BOUTIQUE</li>
          <li (click)="navigateToServices()">SERVICES</li>
          <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
        </ul>
      </div>
    </div>

    <!-- Artist Content -->
    <div class="artist-header" [@slideInRight]>
      <h1>{{artist.name}}</h1>
      <img [src]="getFullImageUrl(artist.image)" alt="Muhoza" class="artist-picture">
    </div>
    <div class="artist-content" [@fadeInContent]>
      <div class="artist-bio-container" [ngClass]="{'show-more': showFullBio}">
        <div class="artist-bio" id="artistBio">
          <h1>Biography</h1>
          <p>
            {{artist.biography}}
          </p>
        </div>
        <button class="see-more" id="seeMoreButton" (click)="toggleSeeMore()">{{ seeMoreButtonText }}</button>
        <div class="btns">
          <button class="btn" (click)="navigateToEvenement()">VOIR SPECTACLE</button>
          <div class="social-links">
            <img src="assets/images/spotify.png" alt="Spotify">
            <img src="assets/images/apple.png" alt="Apple Music">
          </div>
        </div>
      </div>
      <div class="contact-sidebar">
        <div class="spectacle">
          <div class="contact-item">SPECTACLE </div>
        <p style="text-align: center;" >{{artist.spectacle}}</p>
        </div>
        <div class="spectacle">
          <div class="contact-item">DISQUE</div>
        <p style="text-align: center;" >{{artist.disk}}</p>
        </div>
        <div class="spectacle">
          <div class="contact-item">PRESSE</div>
        <p style="text-align: center;" >{{artist.press}}</p>
        </div>
        <div class="spectacle">
          <div class="contact-item">EDITION</div>
        <p style="text-align: center;" >{{artist.edition}}</p>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Photo -->
    <div *ngIf="isPhotoModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>ARTIST'S PHOTOS</h2>
        <button class="modal-close-button" (click)="closePhotoModal()">X</button>
        <div class="modal-body">
          <h3>UPLOAD PHOTO</h3>
          <input type="file" (change)="onFileSelected($event)" accept="image/*">
          <button (click)="confirmPhotoUpload()">Confirm</button>
          <button (click)="closePhotoModal()">Cancel</button>
          <button type="button" (click)="deletePhotos()" >DELETE PHOTOS</button>
        </div>
      </div>
    </div>

    <!-- Modal for Adding Iframe -->
  <div *ngIf="isModalOpen" class="modal-overlay">
    <div class="modal-content">
      <h2>IFRAME</h2>
      <button class="modal-close-button" (click)="closeModal()">X</button>
      <div class="modal-body">
        <h3>ADD IFRAME</h3>
        <input type="text" [(ngModel)]="iframeCode" placeholder="Enter Iframe Code">
        <button (click)="confirmIframe()">Confirm</button>
        <button (click)="closeModal()">Cancel</button>
        <button type="button" (click)="deleteIframe()"  >DELETE IFRAMES</button>
      </div>
    </div>
  </div>

    <div class="iframe-carousel-1" *ngIf="!isMobile" [@fadeInContent]>
      <div class="iframe-header">
        <h1 class="text-what-s-new">NOUVEAUTÉ</h1>
        <button class="btn-add-iframe" *ngIf="isAuthenticated" (click)="openModal()">Add Iframe</button>
      </div>
      <div class="iframe-carousel">
        <div class="iframe-marquee">
          <div class="iframe-marquee-content">
            <ng-container *ngFor="let item of iframes">
              <iframe
                [src]="item.src"
                width="300"
                height="380"
                frameborder="0"
                allowfullscreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy">
              </iframe>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="iframe-carousel" *ngIf="isMobile" [@fadeInContent]>
      <div class="iframe-header">
        <div class="text-what-s-new">NOUVEAUTÉ</div>
        <button class="btn-add-iframe" *ngIf="isAuthenticated" (click)="openModal()">Add Iframe</button>
      </div>
      <div class="carousel">
        <div class="carousel-item" *ngFor="let iframe of iframes; let i = index" [class.active]="i === currentIndex">
          <iframe [src]="iframe.src" width="400" height="580" frameborder="0" allowfullscreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
          </iframe>
        </div>
      </div>
      <div class="controls">
        <button class="prev" (click)="previousItem()">&#8592;</button>
        <button class="next" (click)="nextItem()">&#8594;</button>
      </div>
    </div>

    <div class="pic">
        <h2>PHOTOS</h2>
        <button class="btn-add-photo" *ngIf="isAuthenticated" (click)="openPhotoModal()">Upload Photo</button>
        <div class="photos">
          <div *ngFor="let photo of artist.photos" class="photo-card">
            <img [src]="getFullImageUrl(photo.image)" [alt]="'Photo ' + photo.id">
          </div>
        </div>
    </div>


    <!-- Footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo-foot">
            <h1 class="footer-description notranslate">
              LE CROISSANT FERTILE
            </h1>
            <div class="footer-logo">
              <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
            </div>
          </div>
        <div class="footer-links">
          <div class="frame-186">
            <div class="text-website-links">Lien internet</div>
            <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
            <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>
          </div>
          <div class="frame-185">
            <div class="text-social-platforms">Platformes Sociales</div>
            <div class="text-instagram">
                <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>

              <div class="text-facebook">
                <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
              </div>
          </div>

        </div>
        <div class="footer-bottom">
          <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </div>
</div>
