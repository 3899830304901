import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface Artist {
  name: string;
  image: string;
}

declare global {
  interface Window {
    onGoogleTranslateLoaded: () => void;
  }
}

@Component({
  selector: 'app-page-accueil',
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.scss'],
  animations: [
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInUp', [
      transition('* => *', [
        query('.service-item', [
          style({ opacity: 0, transform: 'translateY(50px)' }),
          stagger(500, [
            animate('2s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ], { optional: true })
      ])
    ]),
    trigger('slideInFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('1s ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ])
    ])
  ]
})
export class PageAccueilComponent implements OnInit, OnDestroy {
  title = 'LE CROISSANT FERTILE';
  showContent = false;
  isBurgerMenuOpen = false;
  isAnimationComplete = false;
  isModalOpen = false;
  isLoginModalOpen = false;
  isSignUp = false;
  iframeCode = '';
  name = '';
  email = '';
  password = '';
  rememberMe = false;
  isLoading = false;
  isAuthenticated: boolean = false;

  isMobile = false; // To determine if the device is mobile
  currentIndex = 0;
  artists: Artist[] = [
    { name: 'Artist Name 1', image: 'muhoza.jpg' },
    { name: 'Artist Name 2', image: 'muhoza.jpg' },
    { name: 'Artist Name 3', image: 'muhoza.jpg' },
    { name: 'Artist Name 4', image: 'muhoza.jpg' },
    { name: 'Artist Name 5', image: 'muhoza.jpg' },
    { name: 'Artist Name 6', image: 'muhoza.jpg' },
    { name: 'Artist Name 7', image: 'muhoza.jpg' },
    { name: 'Artist Name 8', image: 'muhoza.jpg' },
    { name: 'Artist Name 9', image: 'muhoza.jpg' },
    { name: 'Artist Name 10', image: 'muhoza.jpg' },
  ];

  iframes: { src: SafeResourceUrl }[] = [];
  loader = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private viewportScroller: ViewportScroller,
    private sanitizer: DomSanitizer
  ) {}


  ngOnInit() {
    window.addEventListener('load', () => {
      setTimeout(() => {
        this.loader = false;
      }, 100); // Adjust delay if needed
    });
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken') ? true : false;
    this.checkRefresh();
    this.typeTitleText();
    this.addScrollEventListener();
    this.loadArtists();  // Backend call for loading artists
    this.loadIframes();  // Backend call for loading iframes
    this.checkIfMobile();
  }
  Logout(){
    localStorage.removeItem("authToken");
    window.location.reload();
  }
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkIfMobile();
  }

  nextItem() {
    const items = document.querySelectorAll('.carousel-item');
    this.currentIndex = (this.currentIndex + 1) % items.length;
    this.updateCarousel(items);
  }

  previousItem() {
    const items = document.querySelectorAll('.carousel-item');
    this.currentIndex = (this.currentIndex - 1 + items.length) % items.length;
    this.updateCarousel(items);
  }

  updateCarousel(items: NodeListOf<Element>) {
    items.forEach((item, index) => {
      item.classList.toggle('active', index === this.currentIndex);
    });
  }


  currentArtistIndex = 0;
  startArtistRotation() {
    setInterval(() => {
      this.currentArtistIndex = (this.currentArtistIndex + 1) % this.artists.length;
    }, 4000); // Rotate every 8 seconds
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.handleScroll();
  }

  checkRefresh() {
    if (sessionStorage.getItem('isRefreshed') === 'true') {
      sessionStorage.setItem('isRefreshed', 'false');
    } else {
      sessionStorage.setItem('isRefreshed', 'true');
      location.reload();
    }
  }

  typeTitleText() {
    const titleElement = document.getElementById('title');
    if (titleElement) {
      const titleText = this.title;
      let index = 0;
      titleElement.textContent = '';

      const typeEffect = () => {
        if (index < titleText.length) {
          titleElement.textContent += titleText.charAt(index);
          index++;
          setTimeout(typeEffect, 150);
        } else {
          setTimeout(() => {
            this.showContent = true;
            this.isAnimationComplete = true;
          }, 500);
        }
      };

      typeEffect();
    }
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  navigateToArtist() {
    this.router.navigate(['/artist']).then(() => window.scrollTo(0, 0));
  }

  navigateToMain() {
    this.router.navigate(['/']).then(() => window.scrollTo(0, 0));
  }

  navigateToServices() {
    this.router.navigate(['/services']).then(() => window.scrollTo(0, 0));
  }

  navigateToArtists() {
    this.router.navigate(['/artists']).then(() => window.scrollTo(0, 0));
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']).then(() => window.scrollTo(0, 0));
  }

  navigateToContact() {
    this.router.navigate(['/contact']).then(() => window.scrollTo(0, 0));
  }

  navigateToFaq() {
    this.router.navigate(['/faq']).then(() => window.scrollTo(0, 0));
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  confirmIframe() {
    const iframeData = {
      src: this.iframeCode
    };

    this.http.post(`${environment.apiUrl}/iframe/add`, iframeData).subscribe(
      (response: any) => {
        if (response.success) {
          this.iframes.push({ src: this.iframeCode }); // Add the new iframe to the list
          this.closeModal();
          this.iframeCode = ''; // Clear the input
        }
      },
      (error) => {
        console.error('Error adding iframe:', error);
      }
    );
  }

  openLoginModal() {
    this.isLoginModalOpen = true;
  }

  closeLoginModal() {
    this.isLoginModalOpen = false;
  }

  toggleSignUp() {
    this.isSignUp = !this.isSignUp;
  }
  loginObj={
    email:"",
    password:""
  }

  confirmSignIn() {
    this.isLoading = true;
    console.log("Login Object :",this.loginObj );
    this.http.post(`${environment.apiUrl}/auth/login`, this.loginObj)
      .subscribe({
        next: (res: any) => {
          if (res.success && res.data) {
            console.log('Response:', res);
            localStorage.setItem('authToken', res.data.token);
            localStorage.setItem('userRole', res.data.role || '');
            this.loginObj = { email: '', password: '' };
          }
        },
        error: (error) => {
          console.error('Error:', error);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    this.closeLoginModal();
  }

  confirmSignUp() {
    console.log('Name:', this.name);
    console.log('Email:', this.email);
    console.log('Password:', this.password);
    this.closeLoginModal();
  }

  addScrollEventListener() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  deleteIframe(){
    if (confirm(`Are you sure you want to delete Iframes?`)) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/iframe/delete`)
        .subscribe({
          next: (res) => {
            console.log('Iframes deleted successfully:', res);
            this.iframes=[];
            this.closeModal();
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Iframe:', error);
            this.isLoading = false;
          }
        });
    }
  }
  handleScroll() {
    const slideInElements = document.querySelectorAll('.slide-in');
    slideInElements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        el.classList.add('visible');
      }
    });
  }

  loadArtists() {
    this.http.get<{ data: Artist[] }>(`${environment.apiUrl}/artist/all`)
      .subscribe({
        next: (res) => {
          console.log("Artists Data:", res.data);
          this.artists = res.data.map((artist: Artist) => ({
            name: artist.name,
            image: environment.apiUrl + "/" + artist.image
          }));
          console.log("This Artists :", this.artists);
        },
        error: (error) => {
          console.error('Error fetching artists:', error);
        }
      });
  }

  loadIframes() {
    this.http.get<any>(`${environment.apiUrl}/iframe/all`).subscribe(
      response => {
        if (response.success) {
          this.iframes = response.data.map((iframe: any) => ({
            src: this.sanitizer.bypassSecurityTrustResourceUrl(iframe.src)
          }));
          console.log('Iframes Data:', this.iframes);
        }
      },
      error => {
        console.error('Error fetching iframes:', error);
      }
    );
  }

  scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }


}
