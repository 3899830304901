<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-contact">
    <!-- Navbar -->
    <div class="navbar">
        <div class="left-section">
          <div class="sticky-burgerbar">
            <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
          </div>
          <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
        </div>
        <div class="frame-3">
            <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
        </div>
      </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
        <div class="burger-menu">
            <button class="close-button" (click)="toggleBurgerMenu()">X</button>
            <ul>
                <li (click)="navigateToMain()">ACCUEIL</li>

                <li (click)="navigateToArtists()">ARTISTES</li>
                <li (click)="navigateToEvenement()">BOUTIQUE</li>
                <li (click)="navigateToServices()">SERVICES</li>
                <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
            </ul>
        </div>
    </div>

    <!-- Cover Section -->
    <div class="cover" [@slideInRight]>
        <div class="title">
            <h1>CONTACTS</h1>
        </div>
    </div>

    <!-- Contact Section -->
    <div class="contact-section">
        <div class="about-us">
            <div class="text-about-us">À propos de nous</div>
            <div class="about-description">
                <span class="notranslate">Le Croissant Fertile </span> est une maison de disques qui fournit des services de l'industrie à divers artistes indépendants œuvrant dans tous les genres musicaux au Québec.
            </div>
        </div>

        <div class="our-mission">
            <div class="text-our-mission">Notre objectif</div>
            <div class="mission-description">
                Depuis nos débuts, notre mission est de soutenir le développement et l’épanouissement des carrières musicales, tout en permettant aux créateurs et interprètes de rester entièrement indépendants. Depuis sa fondation,  <span class="notranslate">Le Croissant Fertile</span>, composé d’artistes, de mélomanes et de personnalités atypiques, a réussi à créer une atmosphère de coopération saine, valorisant chaque membre de manière équitable.
            </div>
        </div>

        <div class="reach-out">
            <div class="marquee">
                <div class="marquee-content">
                    Écris-nous pour toute demande d'information, proposition de partenariat ou pour rejoindre notre équipe, on ne mord pas (par courriel)!
                </div>
            </div>



            <form class="contact-form">
              <input type="text" [(ngModel)]="contactOBJ.name" name="contactName" placeholder="NAME*" required>
              <input type="email" [(ngModel)]="contactOBJ.email" name="contactEmail" placeholder="EMAIL*" required>
              <textarea placeholder="MESSAGE" [(ngModel)]="contactOBJ.message" name="contactMessage" required></textarea>
              <button type="submit" (click)="sendContactInformation()"  [disabled]="isLoading">
                <ng-container *ngIf="isLoading; else sendText">
                    Sending...
                </ng-container>
                <ng-template #sendText>
                    SEND
                </ng-template>
            </button>
          </form>
            <div class="email-info">INFO&#64;CROISSANTFERTILE.COM</div>
        </div>

        <div class="team-info">
          <div class="team-title">ÉQUIPE</div>
          <div *ngFor="let contact of contactData" class="team-member">
              <div class="member-name">{{ contact.name }}</div>
              <div class="member-title">{{ contact.job }}</div>
              <div class="member-email">{{ contact.email }}</div>
              <button class="btn-add-contact" *ngIf="isAuthenticated" (click)="deleteContact(contact.id)">Delete Contact</button>
          </div>
          <button class="btn-add-contact" *ngIf="isAuthenticated" (click)="openModal()">Add Contact</button>
      </div>
    </div>

    <!-- Modal for Adding Contact -->
    <div *ngIf="isModalOpen" class="modal-overlay">
      <div class="modal-content">
          <h2>CONTACT DETAIL</h2>
          <button class="modal-close-button" (click)="closeModal()">X</button>
          <div class="modal-body">
              <form>
                  <label>NOM</label>
                  <input type="text" [(ngModel)]="contactName" name="contactName" placeholder="Enter Name" required>
                  <label>POSTE</label>
                  <input type="text" [(ngModel)]="contactPoste" name="contactPoste" placeholder="Enter Poste" required>
                  <label>COURRIEL</label>
                  <input type="email" [(ngModel)]="contactEmail" name="contactEmail" placeholder="Enter Email" required>
                  <button type="button" (click)="confirmAddContact()">Confirm</button>
                  <button type="button" (click)="closeModal()">Cancel</button>
              </form>
          </div>
      </div>
  </div>

    <!-- Footer -->
    <div class="footer">
        <div class="footer-content">
            <div class="logo-foot">
                <h1 class="footer-description notranslate">
                  LE CROISSANT FERTILE
                </h1>
                <div class="footer-logo">
                    <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
                </div>
              </div>
            <div class="footer-links">
                <div class="frame-186">
                    <div class="text-website-links">Lien internet</div>
                    <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
                    <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>

                </div>
                <div class="frame-185">
                    <div class="text-social-platforms">Platformes Sociales</div>
                    <div class="text-instagram">
                        <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
                      </div>

                      <div class="text-facebook">
                        <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
                      </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
            </div>
        </div>
    </div>
</div>
