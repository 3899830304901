<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title class="notranslate">Le Croissant Fertile</title>

    <!-- Import Roboto Slab font -->
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

    <!-- Add your component-specific styles -->
    <style>
        /* The content of your `page-acceuil.component.scss` should go here, if needed */
    </style>
</head>
<div class="page-faq">
    <!-- Navbar -->
    <div class="navbar">
        <div class="left-section">
          <div class="sticky-burgerbar">
            <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
          </div>
          <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
        </div>
        <div class="frame-3">
          <div class="text-en-fr"><span (click)="translateLanguage('en')">EN</span>/<span (click)="translateLanguage('fr')">FR</span></div>
        </div>
      </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
      <div class="burger-menu">
        <button class="close-button" (click)="toggleBurgerMenu()">X</button>
        <ul>
            <li (click)="navigateToMain()">ACCUEIL</li>

          <li (click)="navigateToArtists()">ARTISTES</li>
          <li (click)="navigateToEvenement()">BOUTIQUE</li>
          <li (click)="navigateToServices()">SERVICES</li>
          <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
        </ul>
      </div>
    </div>

    <!-- Cover Section -->
    <div class="cover" [@slideInRight]>
      <div class="title" id="title">
        <h1>FAQ</h1>
      </div>
    </div>

    <button class="btn-add-faq" *ngIf="isAuthenticated" (click)="openModal()">Add FAQ</button>


    <!-- FAQ Section -->
    <div class="faq-section">
      <div class="faq-item" *ngFor="let faq of faqs">
        <div class="faq-question">{{ faq.question }}</div>
        <div class="faq-answer">{{ faq.answer }}</div>
        <button *ngIf="isAuthenticated" class="btn-delete" (click)="deleteFaq(faq.id)" >Delete Button</button>

      </div>
    </div>

    <!-- Modal for Adding FAQ -->
    <div *ngIf="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>FAQ DETAIL</h2>
        <button class="modal-close-button" (click)="closeModal()">X</button>
        <div class="modal-body">
          <form>
            <label>QUESTION</label>
            <input type="text" [(ngModel)]="question" name="question" placeholder="Enter Question" required>
            <label>ANSWER</label>
            <input type="text" [(ngModel)]="answer" name="answer" placeholder="Enter Answer" required>
            <button type="button" (click)="confirmAddFAQ()">Confirm</button>
            <button type="button" (click)="closeModal()">Cancel</button>
          </form>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo-foot">
            <h1 class="footer-description notranslate">
              LE CROISSANT FERTILE
            </h1>
            <div class="footer-logo">
              <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
            </div>
          </div>
        <div class="footer-links">
          <div class="frame-186">
            <div class="text-website-links">Lien internet</div>
            <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
            <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>

          </div>
          <div class="frame-185">
            <div class="text-social-platforms">Platformes Sociales</div>
            <div class="text-instagram">
                <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>

              <div class="text-facebook">
                <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank" rel="noopener noreferrer">Facebook</a>
              </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
