import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { trigger, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common'; // Importing DatePipe

interface Event {
  id:number
  artistName: string;
  eventName: string;
  date: any;
  address: string;
  image?: string; // Optional image field
}

@Component({
  selector: 'app-page-evenement',
  templateUrl: './page-evenement.component.html',
  styleUrls: ['./page-evenement.component.scss'],
  animations: [
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ],
  providers: [DatePipe] // Provide DatePipe in the component
})
export class PageEvenementComponent implements OnInit {
  title = 'Prochain Événement';
  showContent = false;
  isBurgerMenuOpen = false;
  isModalOpen = false;
  isTicketModalOpen = false;
  filterText = '';
  eventName = '';
  artistName = '';
  eventDate = '';
  eventAddress = '';
  eventImage: File | null = null;
  ticketQuantity = 1;
  ticketEmail = '';
  selectedEvent: Event | null = null;
  isLoading = false;

  events: Event[] = [];
  filteredEvents: Event[] = [];
  isAuthenticated: boolean = false;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private http: HttpClient,
    private datePipe: DatePipe // Injecting DatePipe
  ) {}

  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    this.loadEvents();
    this.typeTitleText();
  }

  typeTitleText() {
    const titleElement = document.getElementById('title');
    if (titleElement) {
      const titleText = this.title;
      let index = 0;
      titleElement.textContent = '';

      const typeEffect = () => {
        if (index < titleText.length) {
          titleElement.textContent += titleText.charAt(index);
          index++;
          setTimeout(typeEffect, 150);
        } else {
          setTimeout(() => {
            this.showContent = true;
          }, 500);
        }
      };

      typeEffect();
    }
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  navigateTo(route: string) {
    this.router.navigate([`/${route}`]).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.clearModalInputs();
  }
  deleteEvent(id: number) {
    if (confirm('Are you sure you want to delete this event?')) {
      this.isLoading = true;

      this.http.delete(`${environment.apiUrl}/event/delete/${id}`)
        .subscribe({
          next: (res) => {
            console.log('Event deleted successfully:', res);
            // Remove the deleted event from the events arraythis.events = this.events.filter(event => event.id !== id);
            this.filteredEvents = this.filteredEvents.filter(event => event.id !== id);
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting event:', error);
            this.isLoading = false;
          }
        });
    }
  }


  confirmAddEvent() {
    this.isLoading = true;
    if (this.eventName && this.artistName && this.eventDate && this.eventAddress) {
      const formData:any = new FormData();
      formData.append('eventName', this.eventName);
      formData.append('artistName', this.artistName);
      formData.append('date', this.eventDate);
      formData.append('address', this.eventAddress);
      if (this.eventImage) {
        formData.append('image', this.eventImage);
      }
      console.log("Form Data is :", Object.fromEntries(formData));


      this.http.post(`${environment.apiUrl}/event/add`, formData)

        .subscribe({
          next: (res) => {
            console.log('Event added successfully:', res);
            this.loadEvents(); // Reload events to include the new one
            this.isLoading = false;
            this.closeModal();
          },
          error: (error) => {
            console.error('Error adding event:', error);
            this.isLoading = false;
          }
        });
    } else {
      console.error('Please fill in required fields.');
      this.isLoading = false;
    }
  }

  navigateToMain(anchor?: string) {
    this.router.navigate(['/']).then(() => {
      if (anchor) {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  navigateToArtists() {
    this.router.navigate(['/artists']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToServices() {
    this.router.navigate(['/services']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToContact() {
    this.router.navigate(['/contact']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToFaq() {
    this.router.navigate(['/faq']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.eventImage = file;
    }
  }

  clearModalInputs() {
    this.eventName = '';
    this.artistName = '';
    this.eventDate = '';
    this.eventAddress = '';
    this.eventImage = null;
  }

  filterEvents() {
    this.filteredEvents = this.events.filter(event =>
      event.artistName.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  loadEvents() {
    this.http.get<{ success: boolean; data: Event[] }>(`${environment.apiUrl}/event/all`)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.events = res.data.map(event => ({
              ...event,
              date: this.datePipe.transform(event.date, 'fullDate'), // Format the date
              image: `${environment.apiUrl}/${event.image}`
            }));
            this.filteredEvents = this.events; // Ensure the filtered list is updated
          } else {
            console.error('Error fetching events: No success response');
          }
        },
        error: (error) => {
          console.error('Error fetching events:', error);
        }
      });
  }

  openTicketModal(event: Event) {
    this.selectedEvent = event;
    this.isTicketModalOpen = true;
  }

  closeTicketModal() {
    this.isTicketModalOpen = false;
  }
  ticketObj = {
    eventName: "",
    email: "",
    tickets: 0,
    amount: 10,
    currency: "cad",
    description: "LE CROISSANT FERTILE TICKETS"
  };

  confirmPurchase() {
    if (this.ticketQuantity > 0 && this.ticketEmail && this.selectedEvent) {
      this.isLoading = true;
      this.ticketObj = {
        eventName: this.selectedEvent.eventName,
        email: this.ticketEmail,
        tickets: this.ticketQuantity,
        amount: 10, // Assuming 10 is the price per ticket
        currency: "cad",
        description: "LE CROISSANT FERTILE TICKETS"
      };

      // Sending ticketObj to the payment checkout endpoint
      this.http.post<{ url: string }>(`${environment.apiUrl}/payment/checkout`, this.ticketObj)
        .subscribe({
          next: (res) => {
            console.log('Payment initiated successfully:', res);
            this.isLoading = false;
            // Open the Stripe checkout URL in a new tab
            if (res.url) {
              window.open(res.url, '_blank');
            }
            this.closeTicketModal();
          },
          error: (error) => {
            console.error('Error initiating payment:', error);
            this.isLoading = false;
          }
        });
    }
  }


}
