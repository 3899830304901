import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http'; // Import HttpClient
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-artist-page',
  templateUrl: './artist-page.component.html',
  styleUrls: ['./artist-page.component.scss'],
  animations: [
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('300ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('300ms ease-out', style({ height: '0px', opacity: 0 }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class ArtistPageComponent implements OnInit {
  isBurgerMenuOpen = false;
  showFullBio = false;
  showContent = false;
  isModalOpen = false;
  iframeCode = '';
  isPhotoModalOpen = false;
  selectedFile: File | null = null;
  artistId: any;
  isLoading=false;
  artist: any = {};
  iframes: any[] = [];
  isAuthenticated: boolean = false;
  isMobile = false; // To determine if the device is mobile
  currentIndex = 0;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate () {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.artistId = +id;
        this.loadArtist(this.artistId);
        this.loadIframes();
      } else {
        console.error('Artist ID not found in route parameters.');
      }
    });
        this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkIfMobile();
  }

  nextItem() {
    const items = document.querySelectorAll('.carousel-item');
    this.currentIndex = (this.currentIndex + 1) % items.length;
    this.updateCarousel(items);
  }

  previousItem() {
    const items = document.querySelectorAll('.carousel-item');
    this.currentIndex = (this.currentIndex - 1 + items.length) % items.length;
    this.updateCarousel(items);
  }

  updateCarousel(items: NodeListOf<Element>) {
    items.forEach((item, index) => {
      item.classList.toggle('active', index === this.currentIndex);
    });
  }


  getFullImageUrl(imagePath: string): string {
    return `${environment.apiUrl}/${imagePath}`;
  }

  loadIframes() {
    this.http.get<any>(`${environment.apiUrl}/iframe/all?artistId=${this.artistId}`).subscribe(
      response => {
        if (response.success) {
          this.iframes = response.data.map((iframe: any) => ({
            ...iframe,
            src: this.sanitizer.bypassSecurityTrustResourceUrl(iframe.src)
          }));
          console.log('Iframes Data:', this.iframes);
        } else {
          console.error('Failed to load Iframes data.');
        }
      },
      error => {
        console.error('Error loading Iframes data:', error);
      }
    );
  }

  loadArtist(id: number) {
    this.http.get<any>(`${environment.apiUrl}/artist/${id}`).subscribe(
      response => {
        if (response.success) {
          this.artist = response.data;
          console.log('Artist Data:', this.artist);
        } else {
          console.error('Failed to load artist data.');
        }
      },
      error => {
        console.error('Error loading artist data:', error);
      }
    );
  }
  deleteIframe(){
    if (confirm(`Are you sure you want to delete Iframes? :${this.artistId}`)) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/iframe/delete/${this.artistId}`)
        .subscribe({
          next: (res) => {
            console.log('Iframes deleted successfully:', res);
            this.iframes=[];
            this.closeModal();
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Iframe:', error);
            this.isLoading = false;
          }
        });
    }
  }
  deletePhotos(){
    if (confirm(`Are you sure you want to delete Photos? :${this.artistId}`)) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/artist/photos/delete/${this.artistId}`)
        .subscribe({
          next: (res) => {
            console.log('Iframes deleted successfully:', res);
            this.loadArtist(this.artistId)
            this.closeModal();
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Iframe:', error);
            this.isLoading = false;
          }
        });
    }
  }
  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  navigateToMain() {
    this.router.navigate(['/']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  toggleSeeMore() {
    this.showFullBio = !this.showFullBio;
  }

  get seeMoreButtonText() {
    return this.showFullBio ? '▲' : '▼';
  }

  navigateToServices() {
    this.router.navigate(['/services']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToArtists() {
    this.router.navigate(['/artists']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToContact() {
    this.router.navigate(['/contact']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToFaq() {
    this.router.navigate(['/faq']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  confirmIframe() {
    const iframeData = {
      artistId:this.artistId,
      src: this.iframeCode
    };

    this.http.post(`${environment.apiUrl}/iframe/add/`, iframeData).subscribe(
      (response: any) => {
        if (response.success) {
          this.iframes.push({ src: this.iframeCode }); // Add the new iframe to the list
          this.closeModal();
          this.iframeCode = ''; // Clear the input
        }
      },
      (error) => {
        console.error('Error adding iframe:', error);
      }
    );
  }

  openPhotoModal() {
    this.isPhotoModalOpen = true;
  }

  closePhotoModal() {
    this.isPhotoModalOpen = false;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  confirmPhotoUpload() {
    if (this.selectedFile && this.artistId) {
      const formData:any = new FormData();
      formData.append('image', this.selectedFile);
      formData.append('artistId', this.artistId.toString());
      console.log("Photo :", Object.fromEntries(formData));

      this.http.post(`${environment.apiUrl}/artistPhoto/add`, formData).subscribe(
        (response: any) => {
          if (response.success) {
            this.artist.photos.push(response.data); // Add the new photo to the artist's list
            this.closePhotoModal();
            this.selectedFile = null; // Clear the selected file
          }
        },
        (error) => {
          console.error('Error uploading photo:', error);
        }
      );
    } else {
      alert('Please select a file and ensure artist ID is set.');
    }
  }

}
