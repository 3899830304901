<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title class="notranslate">Le Croissant Fertile</title>

  <!-- Import Roboto Slab font -->
  <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap" rel="stylesheet">

  <!-- Add your component-specific styles -->
  <style>
    /* The content of your `page-acceuil.component.scss` should go here, if needed */
  </style>
</head>

<body>

  <div class="page-acceuil">
    <div class="navbar">
      <div class="left-section">
        <div class="sticky-burgerbar">
          <img src="assets/images/burger-bar.jpg" class="burgerbar" (click)="toggleBurgerMenu()">
        </div>
        <div class="text-le-croissant-fertile notranslate" (click)="navigateToMain()">LE CROISSANT FERTILE</div>
      </div>
      <div class="frame-3">
        <div class="text-en-fr"><span id="_en" (click)="translateLanguage('en')">EN</span> / <span id="_fr"
            (click)="translateLanguage('fr')">FR</span></div>
      </div>
    </div>

    <div *ngIf="isBurgerMenuOpen" [@fadeInOut]>
      <div class="burger-menu">
        <button class="close-button" (click)="toggleBurgerMenu()">X</button>
        <ul>
          <li (click)="navigateToMain()">ACCUEIL</li>
          <li (click)="navigateToArtists()">ARTISTES</li>
          <li (click)="navigateToEvenement()">BOUTIQUE</li>
          <li (click)="navigateToServices()">SERVICES</li>
          <li (click)="navigateToContact()">CONTACTEZ NOUS</li>
        </ul>
        <div class="login" *ngIf="!isAuthenticated"  (click)="openLoginModal()">CONNECTION ADMIN</div>
        <div class="login" *ngIf=" isAuthenticated"  (click)="Logout()">Logout</div>
      </div>
    </div>

    <div *ngIf="loader" class="loader">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="cover" [ngClass]="{'visible': !loader}" [@fadeInLogoAndTitle]>
      <div class="logo">
        <img src="assets/images/logo.png" alt="Logo" class="spinning-logo">
      </div>
      <div class="title notranslate" id="title">
        <h1 class='notranslate'  data-original-title="LE CROISSANT FERTILE">{{ title }}</h1>
      </div>
    </div>


    <!-- Modal for Adding Iframe -->
    <div *ngIf="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h2>IFRAME</h2>
        <button class="modal-close-button" (click)="closeModal()">X</button>
        <div class="modal-body">
          <h3>ADD IFRAME</h3>
          <input type="text" [(ngModel)]="iframeCode" placeholder="Enter Iframe Code">
          <button (click)="confirmIframe()">Confirm</button>
          <button (click)="closeModal()">Cancel</button>
          <button type="button" (click)="deleteIframe()">DELETE IFRAMES</button>

        </div>
      </div>
    </div>

    <!-- Modal for Login -->
    <div *ngIf="isLoginModalOpen" class="modal-overlay">
      <div class="modal-content">
        <div *ngIf="isSignUp">
          <h2>SIGN UP</h2>
          <button class="modal-close-button" (click)="closeLoginModal()">X</button>
          <div class="modal-body">
            <form>
              <label>Name*</label>
              <input type="text" [(ngModel)]="name" placeholder="Enter your name" required>
              <label>Email*</label>
              <input type="email" [(ngModel)]="email" placeholder="Enter your email" required>
              <label>Password*</label>
              <input type="password" [(ngModel)]="password" placeholder="Create a password" required>
              <button type="button" (click)="confirmSignUp()">Confirm</button>
              <button type="button" (click)="closeLoginModal()">Cancel</button>
            </form>
          </div>
        </div>
        <div *ngIf="!isSignUp">
          <h2>SIGN IN</h2>
          <button class="modal-close-button" (click)="closeLoginModal()">X</button>
          <div class="modal-body">
            <form>
              <label>Email*</label>
              <input type="email" name="email" [(ngModel)]="loginObj.email" placeholder="Enter your email" required>

              <label>Password*</label>
              <input type="password" name="password" [(ngModel)]="loginObj.password" placeholder="Enter your password"
                required>

              <label>
                <input type="checkbox" name="rememberMe" [(ngModel)]="rememberMe"> Remember me
              </label>

              <button type="button" (click)="confirmSignIn()">Confirm</button>
              <button type="button" (click)="closeLoginModal()">Cancel</button>
            </form>

          </div>
        </div>
      </div>
    </div>

    <!-- What's New Section -->
    <div class="iframe-carousel" *ngIf="showContent && !isMobile" [@fadeInContent]>
      <div class="iframe-header">
        <div class="text-what-s-new">NOUVEAUTÉ</div>
        <button class="btn-add-iframe" *ngIf="isAuthenticated" (click)="openModal()">Add Iframe</button>
      </div>
      <div class="iframe-carousel">
        <div class="iframe-marquee">
          <div class="iframe-marquee-content">

            <div class="iframe-item" *ngFor="let iframe of iframes">
              <iframe [src]="iframe.src" width="300" height="380" frameborder="0" allowfullscreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="iframe-carousel" *ngIf="isMobile && showContent" [@fadeInContent]>
      <div class="iframe-header">
        <div class="text-what-s-new">NOUVEAUTÉ</div>
        <button class="btn-add-iframe" *ngIf="isAuthenticated" (click)="openModal()">Add Iframe</button>
      </div>
      <div class="carousel">
        <div class="carousel-item" *ngFor="let iframe of iframes; let i = index" [class.active]="i === currentIndex">
          <iframe [src]="iframe.src" width="400" height="500" frameborder="0" allowfullscreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
          </iframe>
        </div>
      </div>
      <div class="controls">
        <button class="prev" (click)="previousItem()">&#8592;</button>
        <button class="next" (click)="nextItem()">&#8594;</button>
      </div>
    </div>



    <div class="service-item hidden-left slide-in" id="about-us" *ngIf="showContent" [@slideInFromLeft]>
      <div class="service-text">
        <h2>À propos de nous</h2>
        <div class="text"> <span class="notranslate">Le Croissant Fertile </span> est une maison de disques qui fournit des services de
          l'industrie à divers artistes
          indépendants œuvrant dans tous les genres musicaux au Québec.</div>
      </div>
    </div>

    <div class="service-item hidden-left slide-in" id="notre-objectif" *ngIf="showContent" [@slideInFromLeft]>
      <div class="service-text">
        <h2>Notre objectif</h2>
        <div class="text">Depuis nos débuts, notre mission est de soutenir le développement et l’épanouissement des carrières
          musicales, tout en permettant aux créateurs et interprètes de rester entièrement indépendants.</div>
      </div>
    </div>

    <div class="artists-carousel" *ngIf="showContent" [@fadeInContent]>
      <div class="text-nos-artistes">NOS ARTISTES</div>
      <div class="artist-carousel">
        <div *ngFor="let artist of artists; let i = index" class="artist-card" [class.active]="i === currentArtistIndex"
          [@fadeInOutArtist]="i === currentArtistIndex ? 'in' : 'out'">
          <img [src]="artist.image" [alt]="artist.name">
          <div class="text-artist-name">{{ artist.name }}</div>
        </div>
      </div>
      <div class="artist-buttons">
        <button class="button" (click)="navigateToArtists()">ARTISTS</button>
        <button class="button" (click)="navigateToContact()">CONTACTEZ NOUS</button>
      </div>
    </div>

    <div *ngIf="isAnimationComplete" class="footer">
      <div class="footer-content">
        <div class="logo-foot">
          <h1 class="footer-description notranslate">
            LE CROISSANT FERTILE
          </h1>
          <div class="footer-logo">
            <img src="assets/images/logo.png" alt="Footer Logo" class="spinning-logo">
          </div>
        </div>

        <div class="footer-links">
          <div class="frame-186">
            <div class="text-website-links">Lien internet</div>
            <div class="text-faqs" (click)="navigateToFaq()">FAQs</div>
            <div class="text-faqs" (click)="navigateToContact()">Contactez-nous</div>
          </div>
          <div class="frame-185">
            <div class="text-social-platforms">Platformes Sociales</div>
            <div class="text-instagram">
              <a href="https://www.instagram.com/disquescroissantfertile/" target="_blank"
                rel="noopener noreferrer">Instagram</a>
            </div>

            <div class="text-facebook">
              <a href="https://www.facebook.com/profile.php?id=61551492292716" target="_blank"
                rel="noopener noreferrer">Facebook</a>
            </div>

          </div>
        </div>
        <div class="footer-bottom">
          <div class="text-rights-reserved">© 2024 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
