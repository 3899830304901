import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('fadeInContent', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class ServicesPageComponent implements OnInit {
  isBurgerMenuOpen = false;
  isServiceModalOpen = false; // State for service modal
  services:any  = [

  ];
  isAuthenticated: boolean = false;

  newService = {

    title: '',
    description: '',
    image: ''
  };
  isLoading=false;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private http: HttpClient // Inject HttpClient
  ) {}

  ngOnInit() {
    this.waitForGoogleTranslate().then(() => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (localStorage.getItem('lang')) {
        this.translateLanguage(localStorage.getItem('lang') as string);
        console.log('setting', localStorage.getItem('lang') as string)
        console.log('ngOnint',googleTranslateContainer)
      }
      else{
        this.translateLanguage('fr');
        console.log('default')
      }
      // console.log(googleTranslateContainer.value)
      this.initiate();
    });
  }

  waitForGoogleTranslate(): Promise<void> {
    return new Promise((resolve) => {
      // Function to check if the dropdown is present
      const checkDropdown = () => {
        const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (googleTranslateContainer) {
          resolve(); // Resolve the promise when the dropdown is found
        } else {
          setTimeout(checkDropdown, 100); // Check again after 100ms
        }
      };

      // Start checking for the dropdown
      checkDropdown();
    });
  }

  translateLanguage(lang: string) {
    localStorage.setItem('lang', lang);

    const setLanguage = () => {
      const googleTranslateContainer = document.querySelector('.goog-te-combo') as HTMLSelectElement;

      if (googleTranslateContainer) {
        googleTranslateContainer.value = '';
        googleTranslateContainer.dispatchEvent(new Event('change'));

        // Temporarily hide or remove elements with the class 'no-translate'
        let noTranslateElements = document.querySelectorAll('.notranslate');
        noTranslateElements.forEach(el => {
          const element = el as HTMLElement; // Cast to HTMLElement
          element.setAttribute('data-original-display', element.style.display);
          element.style.display = 'none';
        });

        setTimeout(() => {
          googleTranslateContainer.value = lang;
          googleTranslateContainer.dispatchEvent(new Event('change'));
          console.log('trans func ', googleTranslateContainer, lang, googleTranslateContainer.value);

          if (googleTranslateContainer.value !== lang) {
            console.log('Language not set, retrying...');
            setTimeout(setLanguage, 100); // Retry after a short delay
          } else {
            console.log('Language set to:', lang);

            const restoreVisibility=()=>{
              // Restore the visibility of elements with the class 'no-translate'
              noTranslateElements.forEach(el => {
                const element = el as HTMLElement
                element.style.display = el.getAttribute('data-original-display') || '';
                element.removeAttribute('data-original-display');
                console.log('style', element.style.display)

                if(element.style.display==='none')
                  setTimeout(restoreVisibility, 100);
                // if()
              });
            }

            restoreVisibility()
          }
        }, 100);
      }
    };
    setLanguage();
  }

  initiate() {
    this.isAuthenticated = localStorage.getItem('authToken')?true:false;
    this.loadServices(); // Load services on initialization
  }

  loadServices() {
    this.http.get<{ success: boolean; data: any[] }>(`${environment.apiUrl}/service/all`)
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.services = res.data.map(service => ({
              id: service.id,
              title: service.title,
              description: service.description,
              image: `${environment.apiUrl}/${service.image}` // Adjust if needed
            }));
            console.log("Service is :", this.services);

          } else {
            console.error('Error fetching services: No success response');
          }
        },
        error: (error) => {
          console.error('Error fetching services:', error);
        }
      });
  }

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }

  navigateToMain() {
    this.router.navigate(['/']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToArtists() {
    this.router.navigate(['/artists']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToEvenement() {
    this.router.navigate(['/evenement']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToServices() {
    this.router.navigate(['/services']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToContact() {
    this.router.navigate(['/contact']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  navigateToFaq() {
    this.router.navigate(['/faq']).then(() => {
      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }

  openServiceModal() {
    this.isServiceModalOpen = true;
  }

  closeServiceModal() {
    this.isServiceModalOpen = false;
  }

  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.newService.image = event.target.files[0];
    }
  }

  deleteService(id: number) {
    if (confirm('Are you sure you want to delete this Service?')) {
      this.isLoading = true;
      this.http.delete(`${environment.apiUrl}/service/delete/${id}`)
        .subscribe({
          next: (res) => {
            console.log('Service deleted successfully:', res);
            this.loadServices();
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error deleting Service:', error);
            this.isLoading = false;
          }
        });
    }
  }


  confirmService() {
    console.log("Please add service");

    if (this.newService.title && this.newService.description && this.newService.image) {
      const formData = new FormData();
      formData.append('title', this.newService.title);
      formData.append('description', this.newService.description);
      formData.append('image', this.newService.image);

      this.http.post(`${environment.apiUrl}/service/add`, formData).subscribe({
        next: (res) => {
          console.log('Service added successfully:', res);

          this.newService = {
            title: '',
            description: '',
            image: ''
          };
          this.closeServiceModal();

          // Reload services
          this.loadServices();
        },
        error: (error) => {
          console.error('Error adding service:', error);
        }
      });
    }
  }
}
